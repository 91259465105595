import React from "react";
import { Link } from "gatsby";

const Nav = () => (
  <header className="header">
    <div className="navbar-area navbar-area--hh">
      <div className="container relative">
        <div className="row items-center">
          <div className="w-full">
            <nav className="flex items-center justify-between py-4 navbar navbar-expand-lg">
              <Link className="navbar-brand mr-5" to="/">
                <img
                  src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661873157/hackingheroes/logo_dqifji.svg"
                  width="140px"
                  alt="Logo"
                />
              </Link>
              <div
                className="absolute left-0 z-20 hidden w-full px-5 py-3 duration-300 bg-white shadow lg:w-auto collapse navbar-collapse lg:block top-full mt-full lg:static lg:bg-transparent lg:shadow-none"
                id="navbarOne"
              >
                <ul
                  id="nav"
                  className="items-center content-start mr-auto lg:justify-end navbar-nav lg:flex"
                >
                  <li className="nav-item ml-5 lg:ml-11">
                    <Link to="/#home" className="page-scroll active">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item ml-5 lg:ml-11">
                    <Link to="/#team" className="page-scroll">
                      Team
                    </Link>
                  </li>
                  <li className="nav-item ml-5 lg:ml-11">
                    <Link to="/#programming" className="page-scroll">
                      Programming
                    </Link>
                  </li>
                  <li className="nav-item ml-5 lg:ml-11">
                    <Link to="/#video" className="page-scroll">
                      Hackathon
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Nav;
