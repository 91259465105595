// creat hackathon component and export it
import React from "react";

const Hackathon = () => (
  <>
    <section
      id="programming"
      className="about-section about-section--hackaton relative z-10 pt-150"
    >
      <div className="container">
        <div className="row flex items-center">
          <div className="w-full lg:w-1/2">
            <div className="about-img about-img--left relative z-10 pt-19 pb-19 mb-18 lg:mb-0">
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661710133/hackingheroes/hackaton1_darnr1.jpg"
                alt="Hackathon in Porto"
                className="w-100"
              />
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661875702/hackingheroes/shapes/left-shape_ahrljj.svg"
                alt="Hackathon background"
                className="shape-1"
              />
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661875702/hackingheroes/shapes/left-dots_wxq9m3.svg"
                alt="Hackathon shapes"
                className="shape shape-2"
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="about-content">
              <div className="section-title mb-8">
                <h1 className="mb-6">Quick path to start programming</h1>
                <p className="mb-10 ">
                  HACKING HEROES's project was targeting middle and high school
                  teenagers and teachers working with young people. It included:
                  <ul className="hh-list">
                    <li>programming workshops for youth</li>
                    <li>5-day long hackathon in Porto</li>
                    <li>methodology workshops for teachers</li>
                    <li>educational platform for teachers and pupils </li>
                  </ul>
                </p>
                <p className="mb-10 ">
                  One of the main goals of the project was to eliminate the
                  biases and convince pupils and teachers that programming does
                  not need to be hard and that almost everyone can / should gain
                  at least some basic programming skills.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" className="about-section pt-150">
      <div className="container">
        <div className="row flex items-center">
          <div className="w-full lg:w-1/2">
            <div className="about-content about-content--left">
              <div className="section-title mb-8">
                <h1 className="mb-6 text-4xl md:text-5xl lg:text-4xl xl:text-5xl 2xl:text-6xl">
                  Learn, play and code together
                </h1>
                <p className="mb-10 ">
                  The participants were learning intensively the basics of web
                  technologies and programming in their hometowns for over 4
                  months. They have also worked on project ideas, that evolved
                  into web app proposals.
                </p>
                <p className="mb-10">
                  The most exciting part of the project was its finale - the
                  hackathon in Porto. Polish and Portuguese students met in
                  person for the first time. They voted for the best project
                  ideas, formed small international teams and worked together
                  for 5 days to bring those ideas to live.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 order-first lg:order-last">
            <div className="about-img-2 relative z-10 pt-19 pb-19 mb-18 lg:mb-0">
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661710133/hackingheroes/hackaton2_c5y0r2.jpg"
                alt="Hackathon in Porto - playing and programming"
                className="w-100"
              />
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661875702/hackingheroes/shapes/right-shape_hkocb6.svg"
                alt="Hackathon background 2"
                className="shape shape-1"
              />
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661875702/hackingheroes/shapes/right-dots_gxn7mq.svg"
                alt="Hackathon shapes 2"
                className="shape shape-2"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Hackathon;
