// create simple gatsby IndexPage component and export it

import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hh-sections/hero";
import People from "../components/hh-sections/people";
import Hackathon from "../components/hh-sections/hackathon";
import Video from "../components/hh-sections/video";
import Seo from "../components/global-sections/seo";
const IndexPage = () => (
  <Layout>
    <Hero />
    <People />
    <Hackathon />
    <Video />
  </Layout>
);

export default IndexPage;

export const Head = () => (
  <>
    <Seo />
  </>
);
