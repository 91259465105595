// export hero component

import React from "react";

const Hero = () => (
  <section
    id="home"
    className="hero-section relative bg-no-repeat bg-top z-10 pt-200 pb-13 lg:pb-200 2xl:pb-120"
  >
    <div className="container">
      <div className="row flex items-center relative">
        <div className="w-full lg:w-1/2">
          <div className="hero-content mb-0 lg:mb-6">
            <h1 className="text-white mb-9 text-4xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-5xl 2xl:text-6xl">
              Hacking Heroes
            </h1>
            <p className="text-white text-lg mb-5 xl:pr-18 2xl:pr-120">
              HACKING HEROES is an educational project initiated by two
              friends-programmers – Dominik Kościelak and Joao Matos.
            </p>
            <p className="text-white text-lg xl:pr-18 2xl:pr-120">
              Thanks to the support of befriended organizations Creative
              Initiatives Foundation /Kalisz, Poland/ and the Associação de
              Desenvolvimento do Concelho de Espinho /Espinho, Portugal / the
              project got the funding from the ERASMUS + Strategic Partnership
              for Youth Programme.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="hero-img pt-8 lg:pt-0">
            <img
              src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661872146/hackingheroes/hh-hero_jlu46j.svg"
              alt="Hacking Heroes Educational Project - computer screen"
              className="w-full lg:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
