// create footer component and export it

import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer className="footer bg-cover bg-no-repeat bg-right-top pt-120 mt-25 bg-theme-color md:bg-transparent md:pt-260 lg:pt-260">
    <div className="container">
      <div className="widget-wrapper">
        <div className="row">
          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="footer-widget mb-10 mx-3">
              <div className="logo mb-8">
                <Link to="/">
                  <img
                    src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661875091/hackingheroes/logo-footer_q8d9gp.svg"
                    alt="HackingHeroes.org logo"
                    width="180px"
                  />
                </Link>
              </div>

              <p className="mb-8 text-white sm:pr-13 md:pr-0 2xl:pr-25">
                Follow us on
                <u>
                  <a
                    href="https://www.facebook.com/hackingheroes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </u>
              </p>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="footer-widget mb-10 mx-3">
              <h6 className="mb-6 text-white">Co-financed by:</h6>
              <a
                href="https://erasmus-plus.ec.europa.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661873048/hackingheroes/partner-logos/erasmus_2_qq1rvp.svg"
                  alt="Erasmus+"
                  width="170px"
                />
              </a>
            </div>
          </div>

          <div className="w-full md:w-6/12 lg:w-4/12">
            <div className="footer-widget mb-10 mx-3">
              <h6 className="mb-6 text-white">Organizers:</h6>
              <div className="footer-widget--orgs">
                <a
                  href="https://www.facebook.com/FundacjaRozwojuInicjatywKreatywnych"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "30px" }}
                >
                  <img
                    src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661873048/hackingheroes/partner-logos/frik_blhgrv.svg"
                    alt="Fundacja Frik"
                    width="90px"
                  />
                </a>

                <a
                  href="https://www.adce.pt/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661873048/hackingheroes/partner-logos/adce_kp0cul.svg"
                    alt="ADCE"
                    width="90px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
