import React from "react";

const Video = () => (
  <section id="video" className="feature-extended-section pt-25">
    <div className="feature-extended-wrapper feature-extended-section-video py-18 bg-theme-color bg-opacity-10">
      <div className="container">
        <div className="row flex justify-center">
          <div className="w-full">
            <div className="section-title text-center mb-15">
              <h1 className="mb-6">HACKATHON in PORTO</h1>
              <iframe
                width="750"
                height="450"
                src="https://www.youtube.com/embed/sgVdMFSaruA?controls=0&rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Video;
