import React, { useEffect } from "react";
import "../assets/css/animate.css";
import "../assets/css/tailwindcss.css";
import "../assets/css/custom-hh-styles.css";
import Nav from "../components/global-sections/nav";
import Footer from "./global-sections/footer";

export default function Layout({ children }) {
  useEffect(() => {
    console.log("this", this);

    window.onscroll = function () {
      const header_navbar = document.querySelector(".navbar-area");
      const sticky = header_navbar.offsetTop;
      if (window.pageYOffset > sticky) {
        header_navbar.classList.add("sticky");
      } else {
        header_navbar.classList.remove("sticky");
      }
    };
  }, []);
  return (
    <div>
      <Nav />
      {children}
      <Footer />
    </div>
  );
}
