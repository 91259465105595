// create seo component and export it
import React from "react";

const Seo = () => (
  <>
    <title>HackingHeroes.org</title>
    <meta
      name="description"
      content="HACKING HEROES is an educational project initiated by two friends-programmers – Dominik Kościelak and Joao Matos."
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta
      property="og:image"
      content="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661717721/hackingheroes/Mediamodifier-Design-Template_xvv3rh.png"
    />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:title" content="Hacking Heroes" />
    <meta
      property="og:description"
      content="HACKING HEROES is an educational project initiated by two friends-programmers – Dominik Kościelak and Joao Matos."
    />
    <meta property="og:url" content="https://hackingheroes.org" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Hacking Heroes" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Hacking Heroes" />
    <meta
      name="twitter:description"
      content="HACKING HEROES is an educational project initiated by two friends-programmers – Dominik Kościelak and Joao Matos."
    />
    <meta
      name="twitter:image"
      content="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661717721/hackingheroes/Mediamodifier-Design-Template_xvv3rh.png"
    />

    <link
      rel="shortcut icon"
      type="image/x-icon"
      href="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661872116/hackingheroes/favicon_ov6xrs.ico"
    />
  </>
);

export default Seo;
