// create people component and export it

import React from "react";

const People = () => (
  <section
    id="team"
    className="feature-section feature-section--people pt-120 "
  >
    <div className="container">
      <div className="row flex justify-center">
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <a
              className="people-frame"
              href="https://www.linkedin.com/in/domkoscielak/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874903/hackingheroes/people/dominik_yhxxsz.svg"
                alt="Dominik Kościelak"
                className="w-full lg:w-auto people-img"
              />
            </a>
            <div className="content">
              <h3 className="mb-5">Dominik Kościelak</h3>
              <p className="text-lg">Co-founder and teacher</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <a
              className="people-frame"
              href="https://www.linkedin.com/in/jhpmatos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874903/hackingheroes/people/joao_ddvrt9.svg"
                alt="Joao Matos"
                className="w-full lg:w-auto people-img"
              />
            </a>
            <div className="content">
              <h3 className="mb-5">Joao Matos</h3>
              <p className="text-lg">Co-founder and teacher</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <a
              className="people-frame"
              href="https://www.linkedin.com/in/aleksandra-kubiak-331b4060/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874903/hackingheroes/people/ola_d94jza.svg"
                alt="Aleksandra Kubiak"
                className="w-full lg:w-auto people-img"
              />
            </a>
            <div className="content">
              <h3 className="mb-5">Aleksandra Kubiak</h3>
              <p className="text-lg">Global project coordinator</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <div className="people-frame">
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874904/hackingheroes/people/radek_skdlle.svg"
                alt="Radosław Nawrocki"
                className="w-full lg:w-auto people-img"
              />
            </div>
            <div className="content">
              <h3 className="mb-5">Radosław Nawrocki</h3>
              <p className="text-lg">Scientific consultant for teaching</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <div className="people-frame">
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874903/hackingheroes/people/sandra_bnsjy1.svg"
                alt="Sandra Poupinha"
                className="w-full lg:w-auto people-img"
              />
            </div>
            <div className="content">
              <h3 className="mb-5">Sandra Poupinha</h3>
              <p className="text-lg">Project coordinator - Espinho</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-8/12 lg:w-4/12">
          <div className="text-center px-3 2xl:px-10 py-8">
            <a
              className="people-frame"
              href="https://www.behance.net/muchaDSGN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dl9wcknmm/image/upload/v1661874903/hackingheroes/people/konrad_jw5ypa.svg"
                alt="Konrad Moszyński"
                className="w-full lg:w-auto people-img"
              />
            </a>
            <div className="content">
              <h3 className="mb-5">Konrad Moszyński</h3>
              <p className="text-lg">Graphic designer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default People;
